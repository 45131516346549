import * as React from 'react';
import { useEffect, useState } from 'react';
import { GridSortModel, LicenseInfo } from '@mui/x-data-grid-pro';
import { TableGridProps } from './Table.consts';
import { StyledDataGridTable } from './Table.style';
import { useSelector } from 'react-redux';
import { campaignsPage } from 'app/genericSlices/campaigns';

LicenseInfo.setLicenseKey(
  'e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y',
);

const Table = ({
  tableProps,
  onRowOver,
  onRowOut,
  onRowClick,
  onRowsScrollEnd,
  getRowId,
  gridSortModel,
  onSortModelChange,
  className,
  scrollEndThreshold,
}: TableGridProps) => {
  const { order } = useSelector(campaignsPage.campaignsState);
  const [sortModel, setSortModel] = useState<GridSortModel>(gridSortModel);

  useEffect(() => {
    order?.hasOwnProperty('_score') ? setSortModel([]) : setSortModel(gridSortModel);
  }, [order]);

  useEffect(() => {
    if (sortModel?.length && onSortModelChange) {
      onSortModelChange(
        { [sortModel[0].field]: sortModel[0].sort.toUpperCase(), id: sortModel[0].sort.toUpperCase() } as any,
        sortModel,
      );
    }
  }, [sortModel]);

  return (
    <StyledDataGridTable
      disableColumnSelector
      disableColumnFilter
      rows={tableProps.rows}
      columns={tableProps.columns}
      sortingOrder={tableProps.sortingOrder}
      loading={tableProps.loading}
      filterModel={tableProps.filterModel}
      sortingMode={tableProps.sortingMode}
      // {...(sortModel ? { sortModel } : {})}
      sortModel={tableProps.disableSorting ? [] : sortModel}
      disableSorting={tableProps.disableSorting}
      componentsProps={{
        row: {
          onMouseEnter: onRowOver,
          onMouseLeave: onRowOut,
        },
      }}
      onRowClick={onRowClick}
      // onSortModelChange={(changedSortModel: GridSortModel) => setSortModel(changedSortModel)}
      onSortModelChange={tableProps.disableSorting ? undefined : (changedSortModel: GridSortModel) => setSortModel(changedSortModel)}
      rowsPerPageOptions={[]}
      className={className}
      components={{ NoRowsOverlay: () => <div /> }}
      onRowsScrollEnd={onRowsScrollEnd}
      scrollEndThreshold={scrollEndThreshold? scrollEndThreshold : 400}
      getRowId={getRowId}
      disableVirtualization
      sx={{
        '& .MuiDataGrid-virtualScrollerContent': {
          height: 'unset !important',
        },
      }}
    />
  );
};

export default Table;
